<template>
  <div class="ml-2">
    <v-switch
      @click.stop="isOpen = true"
      v-model="isNominated"
      color="success"
      readonly
      label="I would like to be scouted for Beach NTDP"
      class="shrink mt-0"
      hide-details
    >
    </v-switch>
    <v-navigation-drawer
      right
      v-model="isOpen"
      fixed temporary
      :width="$vuetify.breakpoint.xs ? 350 : 500"
      :permanent="dirty"
    >
      <!-- HEADER -->
      <template v-slot:prepend>
        <v-toolbar color="#152047" tile dense>
          <v-toolbar-title class="white--text">USA National Team Development</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="#ca9700"
            class="white--text"
            fab
            small
            @click.stop="cancel(true)"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <!-- FOOTER -->
      <template v-slot:append>
        <v-card-text>
          To learn more about the Beach National Team Development Program (NTDP)
          <v-btn
          color="#ca9700"
          text
          href="https://usavolleyball.org/play/national-team-development-program/beach-ntdp/"
          target="_blank"
          >
            Click Here
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col cols="6">
              <v-btn
                :color="activeBtn.color"
                :disabled="activeBtn.disabled"
                :loading="activeBtn.loading"
                @click.stop="activeBtn.onClick"
                block
                class="py-9"
              >
                {{ activeBtn.text }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error white--text"
                @click.stop="activeBtn.cancel"
                :disabled="loading"
                block
                class="py-9"
              >
              {{activeBtn.cancelText || 'Cancel'}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
      <!-- BODY -->
      <v-card-text :class="{ 'px-0': window === 1 }">
        <v-container class="pt-0 px-0 text-subtitle-1">
          <v-row dense v-if="!isNominated">
            <v-col cols="12">
              If you would like to be scouted for USA Volleyball’s Beach National Team Development Program (NTDP), please click yes below.
            </v-col>
            <v-col cols="12">
              <v-alert type="warning" :value="true" prominent text>
                By clicking yes, your name and personal information (including contact info) will be shared with USA Volleyball.
                <p class="mt-3" v-if="false">If you do not want your information shared do not click yes.</p>
              </v-alert>
            </v-col>
            <v-col>
              <v-btn
                color="success"
                block
                @click.stop="postRatings"
                :loading="loading"
              >
                Yes, scout me for NTDP
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-alert type="success" :value="true" text prominent>
                You nominated yourself to be scouted on {{ firstNom.dtCreated.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})  }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <p class="title">Help out the scouts!</p>
              <p>To insure that scouts can easily identify and scout you make sure you do the following:</p>
              <ol class="fa-ul">
                <li> <span class="fa-li success--text"><i class="fad fa-check-circle"></i></span>Update your profile picture.</li>
                <li> <span class="fa-li success--text"><i class="fad fa-check-circle"></i></span>Upload any videos you have.</li>
                <li> <span class="fa-li success--text"><i class="fad fa-check-circle"></i></span>Complete your volleyball metrics.</li>
              </ol>
            </v-col>
          </v-row>
        </v-container>
        <v-overlay color="white" v-if="loading">
          <div class="text-center">
            <v-progress-circular indeterminate color="color3" size="64"></v-progress-circular>
          </div>
          <div class="color3--text text-center text-h4">Loading</div>
        </v-overlay>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { darkerColor } from '@/classes/Dark.js'
import { mapGetters } from 'vuex'
import { firstBy } from 'thenby'

export default {
  props: ['player'],
  data () {
    return {
      loading: false,
      history: [],
      isOpen: false,
      verified: false,
      window: 0
    }
  },
  computed: {
    ...mapGetters(['theme']),
    isNominated () {
      return this.history.findIndex(f => f.rating === 'Self Nominate') > -1
    },
    nominations () {
      return this.history.filter(f => f.rating === 'Self Nominate').sort(firstBy('dtCreated'))
    },
    firstNom () {
      return this.nominations && this.nominations.length > 0 && this.nominations[0]
    },
    activeBtn () {
      return {
        color: 'success white--text',
        icon: 'fas fa-times',
        onClick: this.postRatings,
        text: 'save',
        cancel: this.cancel,
        disabled: !this.valid,
        cancelText: this.dirty ? 'Cancel' : 'close'
      }
    },
    dirty () {
      return !this.isNominated && this.verified
    },
    dto () {
      return {
        playerProfileId: this.player.id,
        rating: 'Self Nominate'
      }
    }
  },
  methods: {
    loadRatings () {
      this.loading = true
      this.$VBL.user.ntdp.get(this.player.ppId)
        .then(r => {
          this.setHistory(r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    postRatings () {
      this.loading = true
      this.$VBL.user.ntdp.post([this.dto])
        .then(r => {
          this.setHistory(r.data)
          this.saved = true
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    setHistory (h) {
      h.forEach(f => {
        f.predelete = false
        f.dtCreated = new Date(f.dtCreated)
      })
      this.history = h
    },
    getDarkerColor (color1, color2) {
      return darkerColor(color1, color2)
    },
    cancel () {
      this.isOpen = false
      this.verified = false
    }
  },
  mounted () {
    this.loadRatings()
  }
}
</script>
